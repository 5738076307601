import { Injectable } from '@angular/core';
import { PRODUCTION_BUILD, SYSTEM_NAME } from '../app.settings';
import { BehaviorSubject, Subject } from 'rxjs';

import * as CryptoJS from 'crypto-js';
import { AuthenticationService } from 'src/app/core-frontend-common/auth/authentication.service';

@Injectable()
export class AppCommunicationService {
  // Admin
  public static USER_ROLE_SUPERADMIN = 600;
  public static USER_ROLE_UIK_ADMIN = 500;
  // Basic
  public static USER_ROLE_UIK_UKVS = 4;
  public static USER_ROLE_UIK_J9 = 3;
  public static USER_ROLE_UIK_J5 = 2;
  public static USER_ROLE_UIK_UK = 1;
  public static USER_ROLE_ANONYMOUS = 0;

  public static POSSIBLE_ROLES: string[] = ['SUPERADMIN', 'UIK_ADMIN', 'UIK_UK', 'UIK_J5', 'UIK_J9', 'UIK_UKVS'];
  public static POSSIBLE_BASIC_ROLES: string[] = ['UIK_UK', 'UIK_J5', 'UIK_J9', 'UIK_UKVS'];
  loadingAnimation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingAnimationText: Subject<string> = new Subject<string>();
  isWorkplaceChanged: Subject<boolean> = new Subject<boolean>();

  static decryptData(data) {
    return data;
  }

  static getCurrentUser() {
    return AuthenticationService.getCurrentUser();
  }

  static getWorkplaceFromLocalStorage(): string {
    if (localStorage.getItem('workplace')) {
      return localStorage.getItem('workplace');
    }
    return null;
  }

  constructor() {}

  deleteWorkplaceFromLocalStorage() {
    localStorage.removeItem('workplace');
    this.isWorkplaceChanged.next(false);
  }

  setWorkplaceToLocalStorage(option: string) {
    localStorage.setItem('workplace', option.toString());
    // Ked sa zmeni workplace pri prihlaseni, je potrebne znovu nacitat data v cashdesk
    this.isWorkplaceChanged.next(true);
  }

  isUserLogged(): boolean {
    return this.getRoleOfLoggedUser() > AppCommunicationService.USER_ROLE_ANONYMOUS;
  }

  isUserAdmin(): boolean {
    return this.getRoleOfLoggedUser() >= 100;
  }

  getCurrentUserRoles(): string[] {
    const userRoles: string[] = [];
    const currentUser = AppCommunicationService.getCurrentUser();

    if (!currentUser) {
      return userRoles;
    }

    currentUser.user.roles.forEach(role => {
      if (AppCommunicationService.POSSIBLE_ROLES.includes(role)) {
        userRoles.push(role);
      }
    });
    return userRoles;
  }

  getRoleOfLoggedUser(): number {
    const roles = this.getCurrentUserRoles();

    if (roles.includes('SUPERADMIN')) {
      return AppCommunicationService.USER_ROLE_SUPERADMIN;
    }
    if (roles.includes('UIK_ADMIN')) {
      return AppCommunicationService.USER_ROLE_UIK_ADMIN;
    }
    if (roles.includes('UIK_UKVS')) {
      return AppCommunicationService.USER_ROLE_UIK_UKVS;
    }
    if (roles.includes('UIK_J9')) {
      return AppCommunicationService.USER_ROLE_UIK_J9;
    }
    if (roles.includes('UIK_J5')) {
      return AppCommunicationService.USER_ROLE_UIK_J5;
    }
    if (roles.includes('UIK_UK')) {
      return AppCommunicationService.USER_ROLE_UIK_UK;
    }

    return AppCommunicationService.USER_ROLE_ANONYMOUS;
  }

  loadingOn(text = null) {
    if (text) {
      this.loadingAnimationText.next(text);
    }
    this.loadingAnimation.next(true);
  }

  loadingOff() {
    this.loadingAnimation.next(false);
    this.loadingAnimationText.next(null);
  }
}
