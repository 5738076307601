// Sem patria globalne konstanty

export const SYSTEM_NAME_AUTH = 'AUTH';
export const SYSTEM_NAME_EPC = 'EPC';
export const SYSTEM_NAME_ETD = 'ETD';
export const SYSTEM_NAME_ISBN = 'ISBN';
export const SYSTEM_NAME_ROOMBOOK = 'ROOMBOOK';
export const SYSTEM_NAME_ZP = 'ZP';
export const SYSTEM_NAME_UIK = 'UIK';
export const SYSTEM_NAME_HELPDESK = 'HELPDESK';
export const SYSTEM_NAME_TOOLS = 'TOOLS';
export const SYSTEM_NAME_LIBADMIN = 'LIBADMIN';
export const SYSTEM_NAME_WAREHOUSE = 'WAREHOUSE';
export const SYSTEM_NAME_ALFRED = 'ALFRED';
export const SYSTEM_NAME_HELIOS = 'HELIOS';

export const SYSTEM_FULL_NAME_ETD = 'thesisEvidence';
export const SYSTEM_FULL_NAME_ROOMBOOK = 'roombook';
export const SYSTEM_FULL_NAME_UIK = 'COPYCENTRUM';
export const SYSTEM_FULL_NAME_WAREHOUSE = 'warehouse';
export const SYSTEM_FULL_NAME_LIBADMIN = 'libadmin';

/*
 * USER ROLES
 *
 *
 *
 */
const USER_ROLES = {
  SUPERADMIN: 'SUPERADMIN',
  UIK_J5: 'UIK_J5',
  UIK_UK: 'UIK_UK',
  UIK_ADMIN: 'UIK_ADMIN',
  UIK_UKVS: 'UIK_UKVS',
  UIK_J9: 'UIK_J9',
} as const;

export const UserRoles = {
  All: [
    USER_ROLES.SUPERADMIN,
    USER_ROLES.UIK_J5,
    USER_ROLES.UIK_UK,
    USER_ROLES.UIK_ADMIN,
    USER_ROLES.UIK_UKVS,
    USER_ROLES.UIK_J9,
  ],
  Admins: [
    USER_ROLES.SUPERADMIN,
    USER_ROLES.UIK_ADMIN,
  ],
  Superadmin: USER_ROLES.SUPERADMIN,
  UIK_J5: USER_ROLES.UIK_J5,
  UIK_UK: USER_ROLES.UIK_UK,
  UIK_ADMIN: USER_ROLES.UIK_ADMIN,
  UIK_UKVS: USER_ROLES.UIK_UKVS,
  UIK_J9: USER_ROLES.UIK_J9
} as const;

export const skLocalizationCalendar = {
  firstDayOfWeek: 1,
  dayNames: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
  dayNamesShort: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'],
  dayNamesMin: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'],
  monthNames: [
    'Január',
    'Február',
    'Marec',
    'Apríl',
    'Máj',
    'Jún',
    'Júl',
    'August',
    'September',
    'Október',
    'November',
    'December',
  ],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  today: 'dnes',
  clear: 'Zmazať',
};

export class ConstantsCashboxType {
  public static CASHBOX_TYPE_SELL = 'Predaj';
  public static CASHBOX_TYPE_WITHDRAWAL = 'Výber';
  public static CASHBOX_TYPE_DEPOSIT = 'Vklad';
  public static CASHBOX_TYPE_SET_ACTUAL_BALANCE = 'Priame zadanie stavu';
}

export const COLORS = {
  blueLight: '#92a2a8',
  blue: '#57889c',
  teal: '#568a89',
  blueDark: '#4c4f53',
  green: '#356e35',
  greenDark: '#496949',
  greenLight: '#71843f',
  purple: '#6e587a',
  magenta: '#6e3671',
  pink: '#ac5287',
  pinkDark: '#a8829f',
  yellow: '#b09b5b',
  orange: '#c79121',
  red: '#a90329',
  redLight: '#a65858'
};
