import { CashboxModel } from '../../models/cashbox.model';
import { AppCommunicationService } from '../../services/app-communication.service';
import { CashboxService } from '../../services/cashbox.service';
import { SaveCashboxModel } from '../../models/save-cashbox.model';
import { ModalDirective } from 'ngx-bootstrap';
import { CategoryModel } from '../../models/category.model';
import { ItemMatrix } from './item-matrix.model';
import { ItemService } from '../../services/item.service';
import { ItemModel } from '../../models/item.model';
import { Component, OnInit, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { isNumber } from 'util';
import { SaveCashboxItemModel } from '../../models/save-cashbox-item.model.';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from '../../services/category.service';
import { UserService } from '../../services/user.service';
import { roundPrice } from '../../../core-frontend-common/misc/round-price';
import { first } from 'rxjs/operators';

export class Discounts {
  discount20: boolean;
  discount30: boolean;
  discount50: boolean;
}

@Component({
  selector: 'app-cash-desk-view',
  templateUrl: './cash-desk-view.component.html',
  styleUrls: ['./cash-desk-view.component.scss'],
})
export class CashDeskViewComponent implements OnInit {
  isLoaded = false;

  private readonly POSITION_FIRST_COLUMN_TOP = 'firstColumnTop';
  private readonly POSITION_FIRST_COLUMN_MIDDLE = 'firstColumnMiddle';
  private readonly POSITION_FIRST_COLUMN_BOTTOM = 'firstColumnBottom';

  private readonly POSITION_SECOND_COLUMN_TOP = 'secondColumnTop';
  private readonly POSITION_SECOND_COLUMN_MIDDLE = 'secondColumnMiddle';
  private readonly POSITION_SECOND_COLUMN_BOTTOM = 'secondColumnBottom';

  private readonly POSITION_THIRD_COLUMN_TOP = 'thirdColumnTop';
  private readonly POSITION_THIRD_COLUMN_MIDDLE = 'thirdColumnMiddle';
  private readonly POSITION_THIRD_COLUMN_BOTTOM = 'thirdColumnBottom';

  private readonly POSITION_FOURTH_COLUMN_TOP = 'fourthColumnTop';
  private readonly POSITION_FOURTH_COLUMN_MIDDLE = 'fourthColumnMiddle';
  private readonly POSITION_FOURTH_COLUMN_BOTTOM = 'fourthColumnBottom';

  private readonly POSITION_FIRST_BOTTOM_ROW = 'firstbottomRow';
  private readonly POSITION_LAST_BOTTOM_ROW = 'lastbottomRow';

  private readonly CASHBOX_TYPE_SELL = 'Predaj';

  saving = false;
  isUserAdmin = false;
  items: ItemModel[] = [];
  categories: CategoryModel[] = [];
  itemsByCategory: ItemMatrix;
  itemPieces: number[] = [];
  submitButtonDisabled = true;
  payback: number;
  cashbox: SaveCashboxModel;
  actualBalance: number;
  plu: any[] = [];
  workplaceCode: string = AppCommunicationService.getWorkplaceFromLocalStorage();
  recentCashbox: CashboxModel;
  discounts: Discounts = {
    discount20: false,
    discount30: false,
    discount50: false,
  };

  minItemPrice = 0;

  constructor(
    private itemService: ItemService,
    private categoryService: CategoryService,
    private cashboxService: CashboxService,
    private appCommunicationService: AppCommunicationService,
    private toastr: ToastrService,
    private userService: UserService,
  ) {}

  @ViewChild('lgModal', { static: false }) public lgModal: ModalDirective;
  @ViewChild('paybackModal', { static: false }) public paybackModal: ElementRef;
  public firstFieldFocusTriggeringEventEmitter = new EventEmitter<boolean>();
  public paybackModalFocusTriggeringEventEmitter = new EventEmitter<boolean>();

  setFocusToModal() {
    if (!this.submitButtonDisabled) {
      this.lgModal.show();
    }
  }

  setFocusToFirstField() {
    if (this.lgModal && this.lgModal.isShown) {
      this.lgModal.hide();
    }
    this.firstFieldFocusTriggeringEventEmitter.emit(true);
  }

  ngOnInit() {
    this.payback = 0;
    this.workplaceCode = AppCommunicationService.getWorkplaceFromLocalStorage();

    this.isUserAdmin = this.appCommunicationService.isUserAdmin();

    this.appCommunicationService.isWorkplaceChanged.subscribe(val => {
      if (val) {
        this.isLoaded = false;
        this.reset();
        this.loadData();
        this.workplaceCode = AppCommunicationService.getWorkplaceFromLocalStorage();
      }
    });

    if (this.workplaceCode) {
      this.userService.changeWorkplace(this.workplaceCode).subscribe(() => {
        this.loadData();
      });
    }

    this.initializeCashbox();
    this.setFocusToFirstField();
  }

  loadData() {
    this.initializeMatrix();
    this.loadActualBalance();
    this.loadRecentCashbox();

    this.itemService
      .getMinItemPrice()
      .pipe(first())
      .subscribe(val => (this.minItemPrice = val['valueDecimal'] || 0));

    this.itemService.getAllItemsFromWorkplace(null).subscribe(
      res => {
        this.items = res;
        // console.log(this.items);
        this.categoryService.getAllCategories().subscribe(
          r => {
            this.categories = r;
            this.mapItemsIntoMatrix();
            // console.log(this.categories);
            // console.log(this.itemsByCategory);
            this.setFocusToFirstField();
            this.appCommunicationService.isWorkplaceChanged.next(false);
          },
          error2 => {
            console.log(error2);
          },
        );
      },
      error2 => {
        console.log(error2);
      },
    );
  }

  loadRecentCashbox() {
    this.cashboxService.getCurrentUserLastSell().subscribe(
      res => {
        // console.log(res);
        this.recentCashbox = res;
      },
      error2 => {
        console.log(error2);
      },
    );
  }

  getWorkplaceName() {
    switch (this.workplaceCode) {
      case 'UIK_UK': {
        return 'Copycentrum';
      }
      case 'UIK_J5': {
        return 'Jedlíkova 5';
      }
      case 'UIK_J9': {
        return 'Jedlíkova 9';
      }
      case 'UIK_UKVS': {
        return 'Veľkoplošné služby';
      }
      default: {
        return '';
      }
    }
  }

  isInputValidAsPrice(itemId: number) {
    // zabezpecim, ze to nacita ako cislo
    const value = +this.itemPieces[itemId];
    if (isNaN(value) || value <= 0) {
      // ak je na vstupe string, alebo zaporne cislo, zmazem
      this.itemPieces[itemId] = null;
      this.removeItemFromCashbox(itemId);
    } else {
      // zaokruhlujem na 2 desatinne miesta
      this.itemPieces[itemId] = Math.round(value * 100) / 100;
      this.addItemToCashbox(itemId);
    }
    this.toggleSubmitButton();
    this.computePLU();
  }

  // inputtedValueById(id: number) {
  //   const el = this.items.find(i => i.id === id);
  //
  //   if (el) {
  //     this.isInputValidAsPrice()
  //   }
  // }

  isInputValidAsPieceCount(item) {

    if (!item || !item.id) {
      return;
    }
    // zabezpecim, ze to nacita ako cislo
    const value = +this.itemPieces[item.id];
    if (isNaN(value) || value < 1) {
      // ak je na vstupe string, alebo zaporne cislo, zmazem
      this.itemPieces[item.id] = null;
      this.removeItemFromCashbox(item.id);
    } else {
      // zaokruhlujem na cele
      this.itemPieces[item.id] = Math.floor(value);
      this.addItemToCashbox(item.id);
    }
    this.toggleSubmitButton();
    this.computePLU();
  }

  private computePLU() {
    this.plu = [];
    this.cashbox.items.forEach(cashboxItem => {
      const itemArray = this.items.filter(item => item.id === cashboxItem.itemId);
      const pluItem: any = {
        itemName: '',
        pluString: '',
      };

      pluItem.itemName = itemArray[0].name;
      if (itemArray[0].isOnePieceItem) {
        pluItem.pluString = `${cashboxItem.customItemPrice.toString()} PRICE ${itemArray[0].pluCode} PLU`;
      } else {
        pluItem.pluString = `${cashboxItem.count.toString()} x ${itemArray[0].pluCode} PLU`;
      }
      this.plu.push(pluItem);
    });
  }

  private initializeCashboxItem() {
    const cashboxItem: SaveCashboxItemModel = {
      itemId: 0,
      count: 0,
      customItemPrice: 0,
      isMinPriceRequired: false,
    };
    return cashboxItem;
  }

  private initializeCashbox() {
    this.cashbox = {
      id: 0,
      price: 0,
      employeeDiscount: null,
      type: this.CASHBOX_TYPE_SELL,
      customerPaid: null,
      items: [],
      workplaceCode: AppCommunicationService.getWorkplaceFromLocalStorage(),
    };

    this.discounts.discount20 = false;
    this.discounts.discount30 = false;
    this.discounts.discount50 = false;
  }

  private loadActualBalance() {
    this.cashboxService.getActualBalance().subscribe(
      res => {
        this.actualBalance = +res;
      },
      error2 => {
        console.log(error2);
      },
    );
  }

  private addItemToCashbox(itemId: number) {
    const value = this.itemPieces[itemId];
    const itemArray = this.items.filter(item => item.id === itemId);

    // Zistim, ci uz tato polozka bola pridana
    const cashboxItemArray = this.cashbox.items.filter(cashboxItem => cashboxItem.itemId === itemId);
    if (cashboxItemArray[0]) {
      // Ak uz polozka existuje, zistim, ci sa jedna o 1-kusovu polozky s vlastnou cenou
      // Ak ano, aktualizujem custom cenu
      // ak nie, aktualizujem pocet
      if (itemArray[0].isOnePieceItem) {
        cashboxItemArray[0].customItemPrice = value;
      } else {
        cashboxItemArray[0].count = value;
      }
    } else {
      // ak polozka neexistuje, vytvorim novy SaveCashboxItemModel a v zavislosti
      // ci je to 1-kusova polozka s vlastnou cenou nastavim pocet, alebo sumu
      const cashboxItem = this.initializeCashboxItem();
      cashboxItem.itemId = itemArray[0].id;
      if (itemArray[0].isOnePieceItem) {
        cashboxItem.customItemPrice = value;
        cashboxItem.count = 1;
      } else {
        cashboxItem.count = value;
      }
      cashboxItem.isMinPriceRequired = itemArray[0].isMinPriceRequired;
      this.cashbox.items.push(cashboxItem);
    }
    this.computeCashboxPrice();
  }

  private removeItemFromCashbox(itemId: number) {
    const cashboxItem = this.cashbox.items.filter(cashbox => cashbox.itemId === itemId);
    if (cashboxItem[0]) {
      const index: number = this.cashbox.items.indexOf(cashboxItem[0]);
      if (index !== -1) {
        this.cashbox.items.splice(index, 1);
      }
    }
    this.computeCashboxPrice();
  }

  private computeCashboxPrice() {
    let checkMinPriceAttr = false;

    let price = 0;

    let minItemsPrice = 0;
    // Prechadzam vsetkymi pridanymi polozkami v cashbox
    // Podla toho ci je to 1-kusova polozka s vlastnou cenou, alebo nie
    // pripocitavam sumu
    this.cashbox.items.forEach(cashboxItem => {
      const item = this.items.find(i => i.id === cashboxItem.itemId);

      if (item.isMinPriceRequired) {
        checkMinPriceAttr = true;
      }

      if (item.isOnePieceItem) {
        if (item.isMinPriceRequired) {
          minItemsPrice += cashboxItem.customItemPrice;
        } else {
          price += cashboxItem.customItemPrice;
        }
      } else {
        const itemPrice = cashboxItem.count * item.price;

        if (item.isMinPriceRequired) {
          minItemsPrice += itemPrice;
        } else {
          price += itemPrice;
        }
      }
    });

    if (checkMinPriceAttr) {
      if (minItemsPrice < this.minItemPrice) {
        minItemsPrice = this.minItemPrice;
      }
    }

    price += minItemsPrice;

    // Ak mam zamestnanecku zlavu, udelujem 50%
    if (this.cashbox.employeeDiscount) {
      price = price - (price * this.cashbox.employeeDiscount) / 100;
    }

    this.cashbox.price = roundPrice(Math.round(price * 100) / 100);
    this.computePayback();
  }

  setEmployeeDiscount(value: number) {
    if (value === 20) {
      this.discounts.discount20 = !this.discounts.discount20;
      this.discounts.discount30 = false;
      this.discounts.discount50 = false;
    } else if (value === 30) {
      this.discounts.discount30 = !this.discounts.discount30;
      this.discounts.discount20 = false;
      this.discounts.discount50 = false;
    } else if (value === 50) {
      this.discounts.discount50 = !this.discounts.discount50;
      this.discounts.discount20 = false;
      this.discounts.discount30 = false;
    } else {
      this.discounts.discount20 = false;
      this.discounts.discount30 = false;
      this.discounts.discount50 = false;
    }

    this.cashbox.employeeDiscount = null;
    if (this.discounts.discount20) {
      this.cashbox.employeeDiscount = 20;
    }
    if (this.discounts.discount30) {
      this.cashbox.employeeDiscount = 30;
    }
    if (this.discounts.discount50) {
      this.cashbox.employeeDiscount = 50;
    }

    this.computeCashboxPrice();
  }

  toggleSubmitButton() {
    this.submitButtonDisabled = true;
    this.itemPieces.forEach(itemPiece => {
      if (itemPiece > 0) {
        this.submitButtonDisabled = false;
      }
    });
  }

  reset() {
    this.saving = false;
    this.itemPieces = [];
    this.submitButtonDisabled = true;
    this.initializeCashbox();
    this.payback = 0;
    this.loadActualBalance();
    this.computePLU();
    this.loadRecentCashbox();
    this.setFocusToFirstField();
  }

  computePayback() {
    this.payback = 0;
    if (isNumber(+this.cashbox.customerPaid) && +this.cashbox.customerPaid >= this.cashbox.price) {
      // console.log(this.payback);
      this.payback = Math.round((+this.cashbox.customerPaid - this.cashbox.price) * 100) / 100;
    } else {
      this.cashbox.customerPaid = null;
    }
  }

  confirmCashbox() {
    this.saving = true;
    // Preformatujem string na int ak nie je 0 alebo null
    +this.cashbox.customerPaid > 0 ? +this.cashbox.customerPaid : (this.cashbox.customerPaid = null);

    this.cashbox.minAllowedPrice = this.minItemPrice;

    this.cashboxService.createSell(this.cashbox).subscribe(
      cashbox => {
        const price = +this.cashbox.price;
        this.lgModal.hide();
        this.reset();
        this.saving = false;
        this.toastr.success(`Nákup v sume ${price}€ bol úspešne uložený.`, 'Nákup úspešný');
      },
      error2 => {
        this.lgModal.hide();
        console.log(error2);
        this.saving = false;
        this.toastr.error('Nepodarilo sa uložiť. Kontaktujte prosím podporu.', 'Nákup neúspešný');
      },
    );
  }

  private initializeMatrix() {
    this.itemsByCategory = {
      firstColumnTop: [],
      firstColumnMiddle: [],
      firstColumnBottom: [],

      secondColumnTop: [],
      secondColumnMiddle: [],
      secondColumnBottom: [],

      thirdColumnTop: [],
      thirdColumnMiddle: [],
      thirdColumnBottom: [],

      fourthColumnTop: [],
      fourthColumnMiddle: [],
      fourthColumnBottom: [],

      firstbottomRow: [],
      lastbottomRow: [],
    };
  }

  private mapItemsIntoMatrix() {
    this.initializeMatrix();
    let positionByWorkplace: string;

    this.items.forEach(item => {
      // Ak sa jedna o vymazanu kategoriu, ignorujem
      if (!item.category.isDeleted) {
        // Ak sa jedna o pracovisko UKVS, nacitavaju sa matrix pozicie individualne
        if (this.workplaceCode === 'UIK_UKVS') {
          positionByWorkplace = item.category.cashboxMatrixPositionUKVS;
        } else {
          positionByWorkplace = item.category.cashboxMatrixPosition;
        }

        switch (positionByWorkplace) {
          case this.POSITION_FIRST_COLUMN_TOP:
            this.itemsByCategory.firstColumnTop.push(item);
            break;
          case this.POSITION_FIRST_COLUMN_MIDDLE:
            this.itemsByCategory.firstColumnMiddle.push(item);
            break;
          case this.POSITION_FIRST_COLUMN_BOTTOM:
            this.itemsByCategory.firstColumnBottom.push(item);
            break;
          case this.POSITION_SECOND_COLUMN_TOP:
            this.itemsByCategory.secondColumnTop.push(item);
            break;
          case this.POSITION_SECOND_COLUMN_MIDDLE:
            this.itemsByCategory.secondColumnMiddle.push(item);
            break;
          case this.POSITION_SECOND_COLUMN_BOTTOM:
            this.itemsByCategory.secondColumnBottom.push(item);
            break;
          case this.POSITION_THIRD_COLUMN_TOP:
            this.itemsByCategory.thirdColumnTop.push(item);
            break;
          case this.POSITION_THIRD_COLUMN_MIDDLE:
            this.itemsByCategory.thirdColumnMiddle.push(item);
            break;
          case this.POSITION_THIRD_COLUMN_BOTTOM:
            this.itemsByCategory.thirdColumnBottom.push(item);
            break;
          case this.POSITION_FOURTH_COLUMN_TOP:
            this.itemsByCategory.fourthColumnTop.push(item);
            break;
          case this.POSITION_FOURTH_COLUMN_MIDDLE:
            this.itemsByCategory.fourthColumnMiddle.push(item);
            break;
          case this.POSITION_FOURTH_COLUMN_BOTTOM:
            this.itemsByCategory.fourthColumnBottom.push(item);
            break;
          case this.POSITION_FIRST_BOTTOM_ROW:
            this.itemsByCategory.firstbottomRow.push(item);
            break;
          case this.POSITION_LAST_BOTTOM_ROW:
            this.itemsByCategory.lastbottomRow.push(item);
            break;
          default:
            console.log(`FRONT: Unexpected item cashdesk matrix position '${item.name}'. Skip.`);
        }
      }
    });
    setTimeout(() => {
      this.isLoaded = true;
    }, 200);
  }
}
