/* ### BASE URL ### */
import { StringUtils } from '../core-frontend-common/utils/string-utils';
import {
  PRODUCTION_BUILD,
  SYSTEM_NAME,
  PROD_BUILD_USE_HTTPS,
  DEV_BUILD_USE_HTTPS,
  DEVAPI_BUILD,
  LOCAL_BACKEND,
  LOCAL_AUTH,
} from './app.settings';
import {
  SYSTEM_NAME_ALFRED,
  SYSTEM_NAME_AUTH,
  SYSTEM_NAME_EPC,
  SYSTEM_NAME_ETD,
  SYSTEM_NAME_HELPDESK,
  SYSTEM_NAME_ISBN,
  SYSTEM_NAME_LIBADMIN,
  SYSTEM_NAME_ROOMBOOK,
  SYSTEM_NAME_TOOLS,
  SYSTEM_NAME_UIK,
  SYSTEM_NAME_ZP,
} from './constants';

function getApiProtocol(): string {
  if (PRODUCTION_BUILD) {
    if (PROD_BUILD_USE_HTTPS) {
      return 'https';
    } else {
      return 'http';
    }
  }

  if (DEVAPI_BUILD) {
    if (DEV_BUILD_USE_HTTPS) {
      return 'https';
    } else {
      return 'http';
    }
  }

  return 'http';
}

function getClientProtocol(): string {
  if (PRODUCTION_BUILD) {
    if (PROD_BUILD_USE_HTTPS) {
      return 'https';
    } else {
      return 'http';
    }
  }

  if (DEVAPI_BUILD) {
    if (DEV_BUILD_USE_HTTPS) {
      return 'https';
    } else {
      return 'http';
    }
  }

  return 'http';
}

export const LOCAL_APP_PORTS = {
  [SYSTEM_NAME_EPC]: 0,
  [SYSTEM_NAME_ETD]: 3003,
  [SYSTEM_NAME_ISBN]: 3004,
  [SYSTEM_NAME_ROOMBOOK]: 3001,
  [SYSTEM_NAME_ZP]: 0,
  [SYSTEM_NAME_UIK]: 3002,
  [SYSTEM_NAME_HELPDESK]: 0,
  [SYSTEM_NAME_TOOLS]: 0,
  [SYSTEM_NAME_LIBADMIN]: 0,
  [SYSTEM_NAME_ALFRED]: 3003,
};

export const LOCAL_HOST = 'localhost'; // local frontend host (default localhost)
export const LOCAL_FRONTEND_PORT = '3003'; // local frontend port
export const LOCAL_BACKEND_PORT = '63550'; // local API port

export const PROD_HOST = 'portal.tuke.sk';         // production hostname
export const PROD_HOST_PORT = '';                      // production host port (empty for default)
export const PROD_API_HOST = 'portal.tuke.sk';        // production API hostname
export const PROD_API_HOST_PORT = '';                  // production API host port (empty for default)
export const DEV_HOST = 'portal.dev.msweb.tuke.sk';      // development hostname
export const DEV_HOST_PORT = '';                       // development host port (empty for default)
export const DEV_API_HOST = 'portal.dev.msweb.tuke.sk';     // development API hostname
export const DEV_API_HOST_PORT = '';

// local addresses (used for local development)
export const LOCAL_FRONTEND_URL = `${getClientProtocol()}://${LOCAL_HOST}:${LOCAL_FRONTEND_PORT}`;
const LOCAL_API_URL = `${getApiProtocol()}://${LOCAL_HOST}:${LOCAL_BACKEND_PORT}/api`;

// base URL for frontend (non-local)
export const BASE_FRONTEND_URL = `${getClientProtocol()}://${PRODUCTION_BUILD ?
    (PROD_HOST + (StringUtils.isNullOrEmpty(PROD_HOST_PORT) ? '' : (':' + PROD_HOST_PORT))) :
    (DEV_HOST + (StringUtils.isNullOrEmpty(DEV_HOST_PORT) ? '' : (':' + DEV_HOST_PORT)))}`;

export function getBaseApiUrl(systemName?: string): string {
  if (PRODUCTION_BUILD) {
    return `${getApiProtocol()}://api.${systemName}.${PROD_API_HOST}${
        StringUtils.isNullOrEmpty(PROD_API_HOST_PORT) ? '' : (':' + PROD_API_HOST_PORT)
    }/api`;
  } else if (LOCAL_BACKEND && !DEVAPI_BUILD) {
    return LOCAL_API_URL;
  }

  return `${getApiProtocol()}://api.${systemName}.${DEV_API_HOST}${
      StringUtils.isNullOrEmpty(DEV_API_HOST_PORT) ? '' : (':' + DEV_API_HOST_PORT)
  }/api`;
}

export function getBaseFrontendUrl(systemName?: string): string {
  if (systemName === SYSTEM_NAME_AUTH) {
    return `${getClientProtocol()}://${PRODUCTION_BUILD ? PROD_HOST : DEV_HOST}${
      StringUtils.isNullOrEmpty(PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT) ? '' : (':' + (PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT))
    }`;
  }
  return `${getClientProtocol()}://${systemName}.${PRODUCTION_BUILD ? PROD_HOST : DEV_HOST}${
    StringUtils.isNullOrEmpty(PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT) ? '' : (':' + (PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT))
  }`;
}

export const LIBADMIN_URL = getBaseApiUrl(SYSTEM_NAME_LIBADMIN);

// constructs base URL for API host without suffix
function getCurrentApiHost(): string {
  if (PRODUCTION_BUILD) {
    return `${getApiProtocol()}://${PROD_API_HOST}`;
  } else if (LOCAL_BACKEND) {
    return `${getApiProtocol()}://${LOCAL_HOST}:${LOCAL_BACKEND_PORT}`;
  }

  return `${getApiProtocol()}://${DEV_API_HOST}`;
}

export const BASE_URL = getBaseApiUrl(SYSTEM_NAME);
export const ADMIN_URL = getBaseApiUrl(SYSTEM_NAME_LIBADMIN);
export const CURRENT_API_HOST_URL = getCurrentApiHost();

const API_FACULTY_CODEBOOK = '/codebook/getFaculties';
const API_DEPARTMENT_CODEBOOK = '/codebook/getDepartments/';

export function getAppConfigurationUrl() {
  return `${BASE_URL}/config/client`;
}

export function getSearchCollateFieldOfStudyUrl(phrase) {
  return `${BASE_URL}/codebook/searchCollateFieldOfStudy/${phrase}`;
}

export function getLanguagesUrl() {
  return `${BASE_URL}/codebook/getLanguages`;
}

/* ### USER URLS ### */
const API_GET_USER = '/user';
const API_GET_USER_HISTORICAL = '/user/getWithHistorical/';
const API_GET_USER_PHOTO = '/user/photo/';
const API_GET_SEARCHED_USER = '/user/getSearched';
const API_UPDATE_EXTERNAL_USER = '/user/updateExternalThesisUser/';
const API_SEARCH_USER_COLLATE = '/user/searchCollate/';

/* ### GENERAL URLS ### */
const API_CREATE = '/create';
const API_UPDATE = '/update/';
const API_DELETE = '/delete/';
const API_SOFT_DELETE = '/softDelete/';

/* ### CONTROLLERS URLS ### */
const API_ITEMS = '/item';
const API_CATEGORIES = '/category';
const API_CASHBOX = '/cashbox';
const API_WORKPLACE = '/workplace';
const API_CHANGE_WORKPLACE = '/auth/changeWorkplace';

export function getFacultyCodebookUrl() {
  return BASE_URL + API_FACULTY_CODEBOOK;
}

export function getDepartmentCodebookUrl(facultyId) {
  return `${BASE_URL + API_DEPARTMENT_CODEBOOK}/${facultyId}`;
}

export function getSearchedUserUrl() {
  return BASE_URL + API_GET_SEARCHED_USER;
}

export function getUpdateExternalUserUrl(id) {
  return BASE_URL + API_UPDATE_EXTERNAL_USER + id;
}

export function getSearchUserCollateUrl(phrase) {
  return BASE_URL + API_SEARCH_USER_COLLATE + phrase;
}

export function getUserByIdUrl(id: number) {
  return BASE_URL + API_GET_USER + id;
}

export function getHistoricalUserByIdUrl(id: number) {
  return BASE_URL + API_GET_USER_HISTORICAL + id;
}

export function getUserPhotoUrl(hash: string) {
  return BASE_URL + API_GET_USER_PHOTO + hash;
}

export function getAllAppUsersUrl() {
  return `${BASE_URL + API_GET_USER}/getAllAppUsers`;
}

export function getWorkplaceUrl() {
  return BASE_URL + API_WORKPLACE;
}

export function getItemUrl() {
  return BASE_URL + API_ITEMS;
}

export function getGetAllItemsFromWorkplaceUrl() {
  return `${BASE_URL + API_ITEMS}/getAllFromWorkplace`;
}

export function getCreateItemUrl() {
  return BASE_URL + API_ITEMS + API_CREATE;
}

export function getUpdateItemUrl(id: number) {
  return BASE_URL + API_ITEMS + API_UPDATE + id;
}

export function getSoftDeleteItemUrl(id: number) {
  return BASE_URL + API_ITEMS + API_SOFT_DELETE + id;
}

export function getCategoryUrl() {
  return BASE_URL + API_CATEGORIES;
}

export function getCreateCategoryUrl() {
  return BASE_URL + API_CATEGORIES + API_CREATE;
}

export function getUpdateCategoryUrl(id: number) {
  return BASE_URL + API_CATEGORIES + API_UPDATE + id;
}

export function getSoftDeleteCategoryUrl(id: number) {
  return BASE_URL + API_CATEGORIES + API_SOFT_DELETE + id;
}

export function getCashboxActualBalanceUrl() {
  return `${BASE_URL + API_CASHBOX}/getActualBalance`;
}

export function getCurrentUserLastSellUrl() {
  return `${BASE_URL + API_CASHBOX}/getCurrentUserLastSell`;
}

export function getCreateCashboxUrl() {
  return BASE_URL + API_CASHBOX + API_CREATE;
}

export function getCreateCashboxSellUrl() {
  return `${BASE_URL + API_CASHBOX}/createSell`;
}

export function getAllRecentCashboxUrl() {
  return `${BASE_URL + API_CASHBOX}/getAllRecent`;
}

export function getTodaySellsFromWorkplaceUrl() {
  return `${BASE_URL + API_CASHBOX}/getTodaySellsFromWorkplace`;
}

export function changeWorkplaceUrl() {
  return BASE_URL + API_CHANGE_WORKPLACE;
}

export function getSessionValidityCheckUrl() {
  return `${BASE_URL}/auth/validSession`;
}

export function getAllNotificationsForSystem(isForLoginScreen: boolean, systemName: string) {
  return `${LIBADMIN_URL}/notification?isForLoginScreen=${isForLoginScreen}&application=${systemName}`;
}

export function getMinItemPriceUrl() {
  return `${BASE_URL}/config/key/MinAllowedPrice`;
}

export function getUpdateMinItemPriceUrl() {
  return `${BASE_URL}/config/update`;
}
