import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PRODUCTION_BUILD, SYSTEM_NAME } from 'src/app/+app-custom/app.settings';
import { SYSTEM_NAME_AUTH, UserRoles } from "src/app/+app-custom/constants";
import {
  AUTH_REFRESH_TOKEN_EXPIRATION_THRESHOLD,
  AUTH_TOKEN_EXPIRATION_CHECK_THRESHOLD,
  AUTH_TOKEN_EXPIRATION_THRESHOLD,
  AUTH_TOKEN_REFRESH_INTERVAL,
} from 'src/app/core-frontend-common/auth/auth-constants';
import { APP_CONFIG } from '../../app.config';
import { ALLOWED_ROLE_PREFIX } from '../allowed-role-prefix';
import { StringUtils } from '../utils/string-utils';
import { AppRedirectService } from './app-redirect.service';
import {
  AUTH_BASE_COOKIE_DOMAIN, AUTH_BASE_URL,
  changeEmploymentUrl,
  getEmploymentsUrl,
  getLoginUrl,
  getLogoutUrl
} from "./auth.urls";
import { LoginResourceModel } from './model/login-resource.model';
import { Session } from './model/session.model';
import { getBaseFrontendUrl } from "../../+app-custom/app.urls";

@Injectable()
export class AuthenticationService {
  private mIsRefreshingToken = false;
  private mRefreshTokenInterval;

  public token: string;

  static encryptData(data): string {
    return data;
  }

  static decryptData(data): string {
    return data;
  }

  /* ### Save logged user to local storage ### */
  static saveLoggedUser(response) {
    if (!response) {
      return;
    }

    const jsonData = response;
    const user = {
      user: jsonData.user,
      expires: jsonData.expires,
      refresh_expires: jsonData.refresh_expires,
      access_token: jsonData.access_token,
      refresh_token: jsonData.refresh_token,
      session_guid: jsonData.session_guid,
    };

    const encrypted = AuthenticationService.encryptData(JSON.stringify(user));

    localStorage.setItem(`${APP_CONFIG.app.toLowerCase()}user`, encrypted.toString());
  }

  static getCurrentUser() {
    try {
      const sessionJson = localStorage.getItem(`${APP_CONFIG.app.toLowerCase()}user`);

      if (StringUtils.isNullOrWhitespace(sessionJson)) {
        return undefined;
      }

      const decrypted = AuthenticationService.decryptData(sessionJson);

      if (!sessionJson || !decrypted) {
        localStorage.removeItem(`${APP_CONFIG.app.toLowerCase()}user`);
        return undefined;
      }

      return StringUtils.isNullOrWhitespace(decrypted) ? undefined : JSON.parse(decrypted);
    } catch (e) {

      localStorage.removeItem(`${APP_CONFIG.app.toLowerCase()}user`);

      if (!PRODUCTION_BUILD) {
        console.error(e);
      }
    }

    return undefined;
  }

  static getCurrentUserEncrypted(): string {
    try {
      const sessionJson = localStorage.getItem(`${APP_CONFIG.app.toLowerCase()}user`);

      if (StringUtils.isNullOrWhitespace(sessionJson)) {
        return undefined;
      }

      return StringUtils.isNullOrWhitespace(sessionJson) ? undefined : (sessionJson as string);
    } catch (e) {
      if (!PRODUCTION_BUILD) {
        console.error(e);
      }
    }

    return undefined;
  }

  static getCurrentUserRoles(): string[] {
    const session: Session = AuthenticationService.getCurrentUser();

    if (_.isNil(session) || _.isNil(session.user) || !_.isArray(session.user.roles)) {
      return [];
    }

    return session.user.roles;
  }

  static isUserInRole(reqRole: string): boolean {
    const roles = AuthenticationService.getCurrentUserRoles();

    if (!_.isArray(roles) || !roles.length) {
      return false;
    }

    if (_.indexOf(UserRoles.All, reqRole) === -1) {
      return false;
    }

    return _.indexOf(roles, reqRole) > -1;
  }

  static isUserInRoles(reqRoles: string[]): boolean {
    const userRoles = AuthenticationService.getCurrentUserRoles();

    if (!_.isArray(reqRoles) || !reqRoles.length) {
      return false;
    }

    if (UserRoles.All.some(roles => userRoles.includes(roles))) {
      return reqRoles.some(roles => userRoles.includes(roles));
    }

    return false;
  }

  static updateSavedUser(response) {
    if (!response) {
      return;
    }

    const jsonData = response;
    const currentUser = AuthenticationService.getCurrentUser();

    if (!currentUser) {
      return;
    }

    const user = {
      user: jsonData,
      expires: currentUser.expires,
      refresh_expires: currentUser.refresh_expires,
      access_token: currentUser.access_token,
      refresh_token: currentUser.refresh_token,
      session_guid: currentUser.session_guid,
    };
    const encrypted = AuthenticationService.encryptData(JSON.stringify(user));

    localStorage.removeItem(`${APP_CONFIG.app.toLowerCase()}user`);
    localStorage.setItem(`${APP_CONFIG.app.toLowerCase()}user`, encrypted.toString());
  }

  static updateCurrentUser(
    accessToken: string,
    refreshToken: string,
    expirationTime: number,
    refreshExpirationTime: number,
    sessionGuid: string,
  ) {
    const currentUser = AuthenticationService.getCurrentUser();

    if (!currentUser) {
      return;
    }

    localStorage.removeItem(`${APP_CONFIG.app.toLowerCase()}user`);
    currentUser.access_token = accessToken;
    currentUser.refresh_token = refreshToken;
    currentUser.expires = expirationTime;
    currentUser.refresh_expires = refreshExpirationTime;
    currentUser.session_guid = sessionGuid;

    const encrypted = AuthenticationService.encryptData(JSON.stringify(currentUser));

    localStorage.setItem(`${APP_CONFIG.app.toLowerCase()}user`, encrypted.toString());
  }

  /* ### Check if user is logged in ### */
  // static checkValidCredentials(): boolean {
  //   const currentUser = AuthenticationService.getCurrentUser();

  //   if (!currentUser) {
  //     return false;
  //   }

  //   const expires = currentUser.expires;
  //   return Number.parseInt(Date.now().toString(), 0) - Number.parseInt(expires, 0) <= 1800000;
  // }

  static getServerLocalTimeDifference() {
    let xmlHttp;
    try {
      xmlHttp = new XMLHttpRequest();
    } catch (err1) {
      throw new Error();
    }
    xmlHttp.open('HEAD', window.location.href.toString(), true);
    xmlHttp.setRequestHeader('Content-Type', 'text/html');
    xmlHttp.send('');
    const serverTime = moment(xmlHttp.getResponseHeader('Date'));
    const localTime = moment(new Date());
    return serverTime.diff(localTime, 'seconds');
  }

  static checkExpirationTime(): boolean {
    const currentUser = AuthenticationService.getCurrentUser();

    if (!currentUser) {
      return false;
    }

    const expires = currentUser.expires;
    const now = Date.now();
    const timeDiff = expires - now;

    return expires > now && timeDiff > AUTH_TOKEN_EXPIRATION_THRESHOLD;
  }

  static isExpirationTimeClosingLimit(): boolean {
    const currentUser = AuthenticationService.getCurrentUser();

    if (!currentUser) {
      return false;
    }

    const expires = currentUser.expires;
    const now = Date.now();
    const timeDiff = expires - now;

    if (expires <= now) {
      return false;
    }

    return timeDiff <= AUTH_TOKEN_EXPIRATION_CHECK_THRESHOLD;
  }

  static checkRefreshExpirationTime(): boolean {
    const currentUser = AuthenticationService.getCurrentUser();

    if (!currentUser) {
      return false;
    }

    const expires = currentUser.refresh_expires;
    const now = Date.now();
    const timeDiff = expires - now;

    return expires > now && timeDiff > AUTH_REFRESH_TOKEN_EXPIRATION_THRESHOLD;
  }

  static isSessionExpired(): boolean {
    return !AuthenticationService.checkExpirationTime() && !AuthenticationService.checkRefreshExpirationTime();
  }

  static checkSystemAccessPermission(systemName: string = APP_CONFIG.app.toUpperCase()): boolean {
    const currentUser = AuthenticationService.getCurrentUser();

    if (!currentUser) {
      return false;
    }

    const prefix = ALLOWED_ROLE_PREFIX[systemName];

    if (!prefix) {
      console.log('No prefix for system ', systemName);
      return false;
    }

    for (const role of currentUser.user.roles) {
      if (role === UserRoles.Superadmin || role.lastIndexOf(prefix, 0) === 0) {
        return true;
      }
    }

    return false;
  }

  get tokenRefreshInProgress(): boolean {
    return this.mIsRefreshingToken;
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private appRedirectService: AppRedirectService,
  ) {
    if (AuthenticationService.getServerLocalTimeDifference() > 600) {
      console.log('Bad Time!');
      this.errorMessage(1);
      this.removeUserAndCookie();
      this.appRedirectService.redirectLogin(false);
    }

    clearInterval(this.mRefreshTokenInterval);

    this.mRefreshTokenInterval = setInterval(() => {
      if (this.mIsRefreshingToken) {
        return;
      }

      const currentUser = AuthenticationService.getCurrentUser();

      if (!_.isNil(currentUser)) {
        this.getNewToken(currentUser.user.login, currentUser.refresh_token, currentUser.session_guid).subscribe({
          next: (user: any) => {
            // console.log(user);
          },
          error: (err: any) => {
            // console.log(err);
          },
        });
      }
    }, AUTH_TOKEN_REFRESH_INTERVAL);
  }

  saveLoggedUserFromEncrypted(cipher: string): void {
    if (!cipher) {
      return;
    }

    const decrypted = AuthenticationService.decryptData(cipher);

    if (!StringUtils.isNullOrWhitespace(decrypted) && decrypted !== 'undefined') {
      const parsed = JSON.parse(decrypted);

      this.saveUserCookie(parsed);
      AuthenticationService.saveLoggedUser(parsed);
    }
  }

  checkUser(): boolean {
    if (!AuthenticationService.checkExpirationTime() && !this.mIsRefreshingToken) {
      const currentUser = AuthenticationService.getCurrentUser();

      if (!currentUser) {
        return false;
      }

      this.getNewToken(currentUser.user.login, currentUser.refresh_token, currentUser.session_guid).subscribe({
        error: error => {
          console.log('Error while updating refresh token for user ', error);
        },
      });
    }

    return true;
  }

  /* ### Check if response is not Unauthorized ### */
//   checkUnauthorizedAccess(error, redirectOrLogoutOn401 = true, logout = true): Observable<any> {
//     if (error.status === 401) {
//       if (redirectOrLogoutOn401) {
//         if (logout) {
//           this.logout().subscribe(() => {
//             this.appRedirectService.redirectLogin(false);
//           });
//         } else {
//           this.removeUserAndCookie();
//           this.appRedirectService.redirectLogin(false);
//         }
//       }

//       return throwError('Unauthorized');
//     }

//     return throwError(error);
//   }

  checkTokenRefreshRequirement(): void {
    if (AuthenticationService.isExpirationTimeClosingLimit() && !this.mIsRefreshingToken) {
      const currentUser = AuthenticationService.getCurrentUser();

      if (!currentUser) {
        return;
      }

      this.getNewToken(currentUser.user.login, currentUser.refresh_token, currentUser.session_guid).subscribe({
        error: error => {
          console.log('Error while updating refresh token for user ', error);
        },
      });
    }
  }

  getEmployments(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AuthenticationService.getCurrentUser().access_token}`,
      }),
    };

    return this.http.post(getEmploymentsUrl(), {}, httpOptions).pipe(
      catchError(e => {
        throw e;
      }),
    );
  }

  changeEmployment(employmentCode): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AuthenticationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    const body = `employmentCode=${employmentCode}`;
    return this.http.post(changeEmploymentUrl(), body, httpOptions).pipe(
      map(response => {
        AuthenticationService.updateSavedUser(response);
        return of(response);
      }),
      catchError(e => {
        throw e;
      }),
    );
  }

  login(username: string, password: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const body = new LoginResourceModel(_.trim(username), password);

    return this.http.post(getLoginUrl(), body, httpOptions).pipe(
      map((response: any) => {
        if (!StringUtils.isNullOrWhitespace(response.access_token)) {
          AuthenticationService.saveLoggedUser(response);
          this.saveUserCookie(response);
          return true;
        } else {
          return false;
        }
      }),
      catchError(e => {
        throw e;
      }),
    );
  }

  logout(): Observable<boolean> {
    const isExternal = AuthenticationService.getCurrentUser().user.isExternal || false;

    if (!isExternal) {

      const returnUrl = getBaseFrontendUrl(SYSTEM_NAME_AUTH);
      const url = `${AUTH_BASE_URL}/sso/logout?retUrl=${encodeURIComponent(returnUrl)}`;

      window.open(url, '_self');

      // this.removeUserAndCookie();
      this.token = null;

      return of(true);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AuthenticationService.getCurrentUser().access_token}`,
      }),
    };

    const resultHandler = () => {
      this.token = null;
      this.removeUserAndCookie();
    };

    if (this.checkForUserCookie(false)) {
      return this.http.post(getLogoutUrl(), {}, httpOptions).pipe(
        map(() => {
          resultHandler();

          return true;
        }),
        catchError(error => {
          resultHandler();

          return throwError(error);
        }),
      );
    }

    resultHandler();

    return of(true);
  }

  getNewToken(username, refreshToken, sessionGuid): Observable<any> {
    if (this.mIsRefreshingToken) {
      return EMPTY;
    }

    this.mIsRefreshingToken = true;

    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${AuthenticationService.getCurrentUser().refresh_token}`,
      }),
      responseType: 'json',
    };
    const body = new LoginResourceModel(username, undefined, sessionGuid, refreshToken);

    return this.http.post(getLoginUrl(), body, httpOptions).pipe(
      map((res: any) => {
        const result = res;
        this.mIsRefreshingToken = false;

        this.saveUserCookie(result);
        AuthenticationService.updateCurrentUser(
          result.access_token,
          result.refresh_token,
          result.expires,
          result.refresh_expires,
          result.session_guid,
        );

        return result;
      }),
      catchError(e => {
        this.mIsRefreshingToken = false;

        throw e;
      }),
    );
  }

  // getNewRefreshToken(username, refreshToken, sessionGuid): Observable<any> {
  //   this.mIsRefreshingToken = true;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     }),
  //   };
  //   const body = `login=${username}&refresh_token=${encodeURIComponent(refreshToken)}&session_guid=${sessionGuid}`;
  //   return this._http.post(getLoginUrl(), body, httpOptions).pipe(
  //     map((res: any) => {
  //       const result = res.json();
  //       this.mIsRefreshingToken = false;
  //       this.saveUserCookie(result);
  //       AuthenticationService.updateCurrentUser(
  //         result.access_token,
  //         result.refresh_token,
  //         result.expires_in,
  //         result.session_guid,
  //       );
  //       return result;
  //     }),
  //     catchError(e => {
  //       this.mIsRefreshingToken = false;
  //       return this.checkUnauthorizedAccess(e);
  //     }),
  //   );
  // }

  errorMessage(type: number) {
    // if (type === 1) {
    //     this._notificationService.smallBox({
    //         title: 'Chyba',
    //         content: 'Kvôli nezhode Vaším a serverovým časom Vás nebolo možné prihlásiť. Dovolená odchýlka 10 minút.',
    //         color: '#aa3333',
    //         timeout: 3000
    //     });
    // } else if (type === 2) {
    //     this._notificationService.smallBox({
    //         title: 'Chyba',
    //         content: 'Kvôli nečinnosti ste boli odhlásený. Prosím, prihláste sa znova.',
    //         color: '#aa3333',
    //         timeout: 3000
    //     });
    // }
  }

  checkForUserCookie(updateSessionFromCookie = true): boolean {
    const session = AuthenticationService.getCurrentUser();
    const cookie = this.getUserCookie();

    if (!_.isNil(session) && !cookie) {
      this.removeUserAndCookie();

      return false;
    } else if (updateSessionFromCookie && this.isUpdateFromCookieValid(session, cookie)) {
      if (cookie) {
        AuthenticationService.saveLoggedUser(cookie);

        if (AuthenticationService.isSessionExpired()) {
          this.removeUserAndCookie();

          return false;
        }

        return true;
      }

      this.removeUserAndCookie();

      return false;
    } else if (_.isNil(session) || AuthenticationService.isSessionExpired()) {
      this.removeUserAndCookie();

      return false;
    }

    return true;
  }

  private isUpdateFromCookieValid(user: any, cookie: any): boolean {
    if (!cookie || !cookie.session_guid || !cookie.refresh_token) {
      return false;
    }

    if (!user || !user.session_guid || cookie.session_guid !== user.session_guid) {
      return true;
    }

    let cookieExp;

    if (cookie.expires) {
      cookieExp = cookie.expires;
    } else if (cookie.expires_in) {
      cookieExp = Number.parseInt(cookie.expires_in, 0) * 1000 + Date.now();
    }

    const userExp = Number.parseInt(user.expires, 0);

    if (cookieExp && cookieExp === userExp) {
      return false;
    }

    if (
      cookieExp > userExp &&
      cookie.session_guid === user.session_guid &&
      cookie.refresh_token !== user.refresh_token
    ) {
      return true;
    }

    const diff = cookieExp - userExp;

    return diff > AUTH_TOKEN_EXPIRATION_THRESHOLD;
  }

  private saveUserCookie(user: any): void {
    if (!user) return;

    user._fromCookie = true;
    const encrypted = AuthenticationService.encryptData(JSON.stringify(user));

    // Split encrypted data into chunks if it exceeds the cookie size limit
    const chunkSize = 3000; // Each chunk is around 3 KB to fit comfortably within the 4 KB limit
    const chunks = this.splitStringIntoChunks(encrypted, chunkSize);

    // Save each chunk as a separate cookie
    const baseCookieName = PRODUCTION_BUILD ? 'auth_user' : 'devauth_user';
    chunks.forEach((chunk, index) => {
      const cookieName = `${baseCookieName}_part${index}`;
      this.cookieService.set(
        cookieName,
        chunk,
        undefined,
        '/',
        AUTH_BASE_COOKIE_DOMAIN,
        PRODUCTION_BUILD
      );
    });

    // Save the total number of chunks in an additional cookie
    this.cookieService.set(
      `${baseCookieName}_partsCount`,
      chunks.length.toString(),
      undefined,
      '/',
      AUTH_BASE_COOKIE_DOMAIN,
      PRODUCTION_BUILD
    );
  }

  private getUserCookie(): any {
    const baseCookieName = PRODUCTION_BUILD ? 'auth_user' : 'devauth_user';
    const partsCountStr = this.cookieService.get(`${baseCookieName}_partsCount`);
    const partsCount = parseInt(partsCountStr, 10);

    if (isNaN(partsCount) || partsCount <= 0) {
      return undefined;
    }

    let encryptedData = '';
    for (let i = 0; i < partsCount; i++) {
      const chunk = this.cookieService.get(`${baseCookieName}_part${i}`);
      if (chunk) {
        encryptedData += chunk;
      } else {
        console.error(`Missing cookie part ${i}. Unable to reconstruct user data.`);
        return undefined;
      }
    }

    // Decrypt the reconstructed data
    const decrypted = AuthenticationService.decryptData(encryptedData);
    return StringUtils.isNullOrWhitespace(decrypted) ? undefined : JSON.parse(decrypted);
  }

  private removeUserAndCookie(): void {
    const baseCookieName = PRODUCTION_BUILD ? 'auth_user' : 'devauth_user';
    const partsCountStr = this.cookieService.get(`${baseCookieName}_partsCount`);
    const partsCount = parseInt(partsCountStr, 10);

    // Remove all chunk cookies if they exist
    if (!isNaN(partsCount) && partsCount > 0) {
      for (let i = 0; i < partsCount; i++) {
        const cookieName = `${baseCookieName}_part${i}`;
        this.cookieService.delete(cookieName, '/', AUTH_BASE_COOKIE_DOMAIN);
      }
      this.cookieService.delete(`${baseCookieName}_partsCount`, '/', AUTH_BASE_COOKIE_DOMAIN);
    }

    // Also clear localStorage if applicable
    localStorage.removeItem(SYSTEM_NAME.toLowerCase() + 'user');
  }

// Utility function to split a string into smaller chunks
  private splitStringIntoChunks(str: string, chunkSize: number): string[] {
    const chunks: string[] = [];
    let index = 0;

    while (index < str.length) {
      chunks.push(str.slice(index, index + chunkSize));
      index += chunkSize;
    }

    return chunks;
  }
}
